import React, { useState } from "react";
import Login from "../../components/Login";
import background from "../../assets/img/backgroundutama.png";
import backgroundMobile from "../../assets/img/backgroundmobile.png";
import overlay from "../../assets/img/siswa.png"; //overlay
import logo from "../../assets/img/logo.png"; //Logo
import logoMan2 from "../../assets/img/logoman2.png"; //Logo man 2 bantul
import Carousel from "../../components/Carousel";
import Data from "../../components/Data";
import DataMobile from "../../components/Data/mobile";
import Menu from "../../components/Menu";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FaBars } from "react-icons/fa";

function Index(props) {
  const appName = "Login";
  const [showLogin, setShowLogin] = useState(false);
  const deskripsi = "Selamat datang di";
  const deskripsi2 = "Belum punya akun? Silakan hubungi Admin Madrasah";
  const title2 = "SMART MANDABA";
  const slogan1 = "Sistem Pembelajaran Berbasis Teknologi";
  const slogan2 = "MAN 2 Bantul";
  const copyright = "2024 Smart MAN 2 Bantul. - Didukung oleh";
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <style>
        {`
          .custom-navbar .navbar-toggler {
            color: white;
            margin-left: auto;
          }

          .custom-navbar .navbar-collapse {
            justify-content: flex-end;
          }

          .nav-link-custom {
            color: white;
            transition: color 0.3s ease, text-decoration 0.3s ease; /* Menambahkan transisi untuk efek halus */
            text-decoration: none; /* Menghilangkan garis bawah default */
          }

          .nav-link-custom:hover {
            color: lightgreen; /* Warna hijau muda saat hover */
            text-decoration: underline; /* Menambahkan garis bawah saat hover */
            text-decoration-color: lightgreen; /* Mengatur warna garis bawah menjadi hijau muda */
          }

          @media (max-width: 991.98px) {
            .custom-navbar {
              background-color: transparent;
            }
            .custom-navbar .navbar-toggler {
              color: black !important;
              margin-right: 10px
            }
            .custom-navbar .nav-link {
              color: black !important;
              background-color: white !important;
              
            }
             .logoMan2 {
              width: 200px,
              height: 40px,
             }
            .nav-link-custom {
              color: black !important; /* Mengubah warna teks menjadi hitam di mobile */
              padding-left: 30px
            }
            .nav-link-custom:hover {
              color: red !important; /* Warna hijau muda saat hover di mobile */
            }
          }
        `}
      </style>
      <Navbar bg="transparent" expand="lg" className="fixed-top custom-navbar">
        <div className="logoMan2">
          <img
            src={logoMan2}
            alt={appName}
            style={{
              width: 300,
              height: 60,
            }}
          />
        </div>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={toggleMobileMenu}
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className={isMobileMenuOpen ? "show" : ""}
        >
          <Nav
            className="ml-auto"
            style={{ marginLeft: "auto", marginRight: "1%", color: "white" }}
          >
            <Nav.Link href="https://man2bantul.id/" className="nav-link-custom">
              Web Utama
            </Nav.Link>
            <Nav.Link
              href="https://man2bantul.id/inovasi-madrasah/"
              className="nav-link-custom"
            >
              Inovasi Madrasah
            </Nav.Link>
            <Nav.Link
              href="https://man2bantul.id/prestasi-madrasah/"
              className="nav-link-custom"
            >
              Prestasi Madrasah
            </Nav.Link>
            <Nav.Link
              href="https://man2bantul.id/prestasi-guru/"
              className="nav-link-custom"
            >
              Prestasi Guru
            </Nav.Link>
            <Nav.Link
              href="https://man2bantul.id/prestasi-siswa/"
              className="nav-link-custom"
            >
              Prestasi Siswa
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div className="col-12 hero-container">
        <div
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 100%, rgba(255, 255, 255, 0) 100%), url(${background})`,
          }}
          className="hero-background col-12"
        ></div>
        <div
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 100%, rgba(255, 255, 255, 0) 100%), url(${backgroundMobile})`,
          }}
          className="hero-background hero-bg-mobile col-12"
        ></div>
        <div
          className="col-12 hero"
          style={{
            backgroundPosition: "0 100%",
            backgroundRepeat: "no-repeat",
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 81.78%, rgba(255, 255, 255, 0) 95.16%), url(${props.overlay})`,
          }}
        >
          <div className="">
            <div className="text-light" data-page={appName} style={{}}>
              <div
                className="menu"
                style={{ textAlign: "right", textColor: "white" }}
              ></div>
              <div className="title-mobile">
                <div className="logo">
                  <img
                    src={logo}
                    alt={appName}
                    style={{ width: 230, height: 70, marginTop: 150 }}
                  />
                </div>
                <h1
                  style={{
                    marginTop: "20px",
                    fontSize: "270%",
                    textAlign: "center",
                  }}
                >
                  {title2}
                </h1>
                <i style={{ fontSize: "200%", textAlign: "center" }}>
                  {slogan1} {slogan2}
                </i>

                <br></br>
                <div
                  className="login"
                  style={{
                    display: "inline-table",
                    width: "50px",
                    padding: "5px 50px 5px 50px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    marginRight: "5px",
                    cursor: "pointer",
                    background: "#2196f3",
                    borderRadius: 20,
                  }}
                  onClick={() => setShowLogin(true)}
                >
                  <span
                    style={{
                      fontSize: "150%",
                      textAlign: "left",
                      fontFamily: "Poppins",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Login
                  </span>
                </div>
                <p
                  style={{
                    fontSize: "10px",
                    fontFamily: "Poppins",
                  }}
                >
                  {deskripsi2}
                </p>
              </div>
              <div
                className="container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "right",
                  paddingRight: "9vw",
                }}
              >
                <div className="title" style={{}}>
                  <img
                    src={logo}
                    alt={appName}
                    style={{
                      width: "20vw",
                      height: "auto",
                      marginTop: 150,
                      marginBottom: "2vh",
                      marginRight: "130%",
                    }}
                  />
                </div>
                <div
                  className="deskripsi"
                  style={{ textAlign: "right", maxWidth: "80vw" }}
                >
                  <div className="slogan">
                    <p
                      style={{
                        fontSize: "2vw",
                        marginTop: "2vh",
                        fontFamily: "Poppins",
                      }}
                    >
                      {deskripsi}
                    </p>
                    <br />
                    <p
                      style={{
                        fontSize: "2.5vw",
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                      }}
                    >
                      {title2}
                    </p>
                    <p
                      style={{
                        fontSize: "20px",
                        fontFamily: "Poppins",
                        fontStyle: "italic",
                      }}
                    >
                      {slogan1} {slogan2}
                    </p>
                    <div
                      className="login"
                      style={{
                        padding: "0 3vw",
                        cursor: "pointer",
                        background: "#2196f3",
                        borderRadius: "25px",
                        width: "15vw",
                        height: "5vh",
                        marginTop: "2vh",
                      }}
                      onClick={() => setShowLogin(true)}
                    >
                      <p
                        style={{
                          fontSize: "1vw",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          color: "#fff",
                          fontWeight: "bold",
                          margin: 0,
                          lineHeight: "5vh",
                        }}
                      >
                        Login
                      </p>
                    </div>
                    <p
                      style={{
                        fontSize: "13px",
                        fontFamily: "Poppins",
                      }}
                    >
                      {deskripsi2}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showLogin && (
            <div
              onClick={(e) => setShowLogin(false)}
              style={{
                position: "fixed",
                backdropFilter: "blur(4px)",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ maxWidth: "600px", width: "100%" }}
                onClick={(e) => e.stopPropagation()}
              >
                <Login />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Index;
